import useTrackers from "./useTrackers";
import { Notification } from "@/fetch/notifications";
import {
  AcademyIcon,
  ChatIcon,
  CloseIcon,
  GlobalIcon,
  MarketplaceIcon,
} from "@/components/Icon";
import { SvgIconProps } from "@/components/Icon/SvgIcon";
import { UrlObject } from "url";
import { useTripsQuery } from "@/fetch/gworld";
import { ParsedUrlQueryInput } from "querystring";
import useLock from "./useLock";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import { TripStatus } from "@/fetch/gworld/useTripsQuery";
// Notification Intents are used for handling notification item actions.
// So if you are changing these make sure you are reflecting
// the changes in the below places as well:
// https://bitbucket.org/gwat/notification-schema/src/main/src/schema/gworld/

type TranslatedIntent = {
  path?: UrlObject | string;
  Icon: React.FC<SvgIconProps>;
  onClick?: () => void;
};

const useNotificationAction = () => {
  const { track } = useTrackers();
  const { data: trips } = useTripsQuery();
  const { isTripLocked } = useLock();

  const getValidPath = (path: UrlObject) => {
    const tripId = Number((path.query as ParsedUrlQueryInput)?.tripId ?? 0);
    const trip = trips?.find((item) => item.id === tripId);
    const isRequestedTripLocked = isTripLocked(trip);
    const isTripCompleted = trip?.status === TripStatus.Complete;
    const hasAccessToTheTrip = Boolean(trip?.id) && !isRequestedTripLocked;

    if (
      !hasAccessToTheTrip ||
      (isTripCompleted && path.pathname?.includes("/social/"))
    )
      return {
        pathname: "/experience/dashboard",
        query: { dialog: TopLevelDialogNames.RestrictedPage },
      };
    else return path;
  };

  const translateNotification = (schema: Notification): TranslatedIntent => {
    try {
      const onClick = () =>
        track("Notification Item Clicked", {
          eventId: "notification-item-clicked",
          intent: schema.intent,
          data: "data" in schema ? schema.data : undefined,
        });

      switch (schema.intent) {
        case "gworld/social/post/like":
          return {
            path: getValidPath({
              pathname: "/social/profile",
              query: {
                dialog: "post",
                id: schema.data.post_id,
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/social/post/comment":
          return {
            path: getValidPath({
              pathname: "/social/profile",
              query: {
                dialog: "post",
                id: schema.data.post_id,
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/social/access/status":
          return {
            path: getValidPath({
              pathname: "/social/timeline",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/social/post/delete":
          return {
            path: getValidPath({
              pathname: "/social/profile",
              query: {
                tripId: schema.data.trip_id ?? undefined,
                dialog: TopLevelDialogNames.Violation,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/social/comment/delete":
          return {
            path: getValidPath({
              pathname: "/social/timeline",
              query: {
                tripId: schema.data.trip_id ?? undefined,
                dialog: TopLevelDialogNames.Violation,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/experience/document/status":
          return {
            path: getValidPath({
              pathname: "/experience/documents",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
              hash: schema.data.document_type,
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/social/post/create":
          return {
            path: getValidPath({
              pathname: "/social/timeline",
              query: {
                dialog: "post",
                id: schema.data.post_id,
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/social/post/reply":
          return {
            path: getValidPath({
              pathname: "/social/timeline",
              query: {
                dialog: "post",
                id: schema.data.post_id,
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: ChatIcon,
          };
        case "gworld/experience/payment/invoice":
          return {
            path: getValidPath({
              pathname: `/experience/payments/invoice/${schema.data.invoice_id}`,
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/resume/request":
          return {
            path: getValidPath({
              pathname: "/experience/resume",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/aupair/request":
          return {
            path: getValidPath({
              pathname: "/experience/au-pair-profile",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/academy":
          return {
            path: getValidPath({
              pathname: "/academy",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: AcademyIcon,
          };
        case "gworld/academy/course/new":
          return {
            path: getValidPath({
              pathname: Boolean(schema.data.course_id)
                ? `/academy/${schema.data.course_id}`
                : "/academy",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: AcademyIcon,
          };
        case "gworld/marketplace":
          return {
            path: getValidPath({
              pathname: "/marketplace",
              query: {
                tripId: schema.data.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: MarketplaceIcon,
          };
        case "gworld/marketplace/deal/new":
          return {
            path: getValidPath({
              pathname: "/marketplace",
              query: {
                ...(schema.data.deal_id
                  ? { dialog: "market-deal", id: schema.data.deal_id }
                  : {}),
                tripId: schema.data?.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: MarketplaceIcon,
          };
        case "gworld/experience/reward/status":
          return {
            path: getValidPath({
              pathname: "/rewards",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                tab: "reward-center",
                dialog: schema.data?.submission_type,
                submissionId: schema.data?.submission_id,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/reward/redeem":
          return {
            path: getValidPath({
              pathname: "/rewards",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                tab: "claim-reward",
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/reward/code":
          return {
            path: getValidPath({
              pathname: "/rewards",
              query: {
                dialog: TopLevelDialogNames.Invitation,
                tripId: schema.data?.trip_id ?? undefined,
                tab: "reward-center",
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/dashboard/task":
          return {
            path: getValidPath({
              pathname: "/experience/dashboard",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/dashboard/expert":
          return {
            path: getValidPath({
              pathname: "/experience/dashboard",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                dialog: TopLevelDialogNames.Experts,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/arrival/orientation":
          return {
            path: getValidPath({
              pathname: "/experience/arrival",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/jobs/unlock":
          return {
            path: getValidPath({
              pathname: "/experience/jobs",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                tab: "job-opportunities",
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/jobs/interview":
          return {
            path: getValidPath({
              pathname: "/experience/jobs",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                tab: "applied",
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/jobs/confirm":
          return {
            path: getValidPath({
              pathname: "/experience/jobs",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
                tab: "offered",
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/tripDetails/plsDocument":
          return {
            path: getValidPath({
              pathname: "/experience/trip-details",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        case "gworld/experience/visa/unlock":
          return {
            path: getValidPath({
              pathname: "/experience/visas",
              query: {
                tripId: schema.data?.trip_id ?? undefined,
              },
            }),
            onClick,
            Icon: GlobalIcon,
          };
        default:
          return {
            onClick,
            Icon: GlobalIcon,
          };
      }
    } catch (error) {
      console.error("Notification schema is incorrect", error);
      return { Icon: CloseIcon };
    }
  };

  return { translateNotification };
};

export default useNotificationAction;
