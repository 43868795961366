/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import cx from "classnames";
import Dialog, { DialogContent } from "@/components/Dialog";
import { IconButton } from "@/components/Button";
import { SocialMediaAssetType } from "@/fetch/websiteContents";
import { CloseIcon } from "@/components/Icon";
import Avatar from "@/components/Avatar";
import Typography from "@/components/Typography";
import { useResponsive, useTrackers } from "@/hooks";
import styles from "./InstagramPostDialog.module.scss";

const InstagramPostDialog: FC<{
  post: SocialMediaAssetType;
  onClose?: () => void;
}> = ({ onClose, post }) => {
  const { isMobile } = useResponsive();
  const { track, captureException } = useTrackers();

  //   const formattedDate = dayjs((post?._ca || 0) * 1000)?.format(
  //     "dddd, DD MMMM YYYY - HH:mm"
  //   );

  const decodedTitle = decodeURIComponent(post.title);

  const profileHeader = (
    <>
      <div className={styles.topBar}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.profileContainer}>
        <Avatar src={post.avatar_url} />
        <div className={styles.profileContent}>
          <div className={styles.titleContainer}>
            <Typography className={styles.division}>
              {/* <DotSeparator color="info" size="0.25rem" /> */}
              {post.user_name}
            </Typography>
          </div>

          {/* <Typography variant="body2" color="text.secondary">
            {formattedDate}
          </Typography> */}
        </div>
      </div>
    </>
  );

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{
        paper: cx(styles.dialogPaper),
      }}
    >
      <DialogContent className={styles.dialogContent}>
        {isMobile && profileHeader}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt={post.title}
          src={post.medium_image}
          className={styles.postImage}
        />

        <div className={cx(styles.container)}>
          {!isMobile && profileHeader}

          <div className={styles.content}>
            <Typography className={styles.text} variant="subtitle2">
              {decodedTitle}
            </Typography>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InstagramPostDialog;
