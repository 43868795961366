import { useRouter } from "next/router";
import { IconButton } from "@/components/Button";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import cx from "classnames";
import { useLock, useResponsive, useCurrentTrip } from "@/hooks";

const RewardIconButton: React.FC<{ className?: string }> = ({ className }) => {
  const router = useRouter();
  const { isAllowedToNavigate } = useLock();
  const { isMobile } = useResponsive();

  return isAllowedToNavigate && isMobile ? (
    <IconButton
      onClick={() => router.push("/rewards?tab=reward-center")}
      className={cx(className)}
    >
      <GlobalDollarColoredIcon />
    </IconButton>
  ) : null;
};

export default RewardIconButton;
