import { FC, useState, ChangeEvent } from "react";
import Typography from "@/components/Typography";
import Tag from "@/components/Tag";
import FAQ from "@/components/FAQ";
import TextField from "@/components/TextField";
import Button from "@/components/Button";
import {
  useFaqCategoriesQuery,
  useFaqsQuery,
  FaqCategory,
} from "@/fetch/gworld";
import { Skeleton } from "@/components/Loader";
import SubmitQuestionDialog from "./SubmitQuestionDialog";
import styles from "./HelpCentreTab.module.scss";

const HelpCentreTab: FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<
    Array<FaqCategory>
  >([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [isSubmitQuestionDialogOpen, setIsSubmitQuestionDialogOpen] =
    useState<boolean>(false);

  const { data: faqCategories, isLoading: isLoadingFaqCategories } =
    useFaqCategoriesQuery();
  const { data: faqsData, isLoading: isLoadingFaqs } = useFaqsQuery();

  const faqs = faqsData?.answeredFAQs || [];

  // const noAnswerFound = true;

  const categorizedFaqs = (faqCategories?.faqCategories || [])
    .map((each) => ({
      category: each,
      questions: faqs.filter((eachFaq) => eachFaq.category_id === each.id),
    }))
    .filter((each) => each.questions.length > 0);

  const filteredCategorizedQuestions = categorizedFaqs
    .filter((each) => {
      const filteredQuestions = each.questions.filter(
        (eachQuestion) =>
          (eachQuestion.question || "")
            ?.toLowerCase()
            .indexOf(searchKey.toLowerCase()) > -1 ||
          (eachQuestion.answer || "")
            ?.toLowerCase()
            .indexOf(searchKey.toLowerCase()) > -1
      );
      if (filteredQuestions.length > 0) {
        return true;
      }
      return false;
    })
    .map((each) => ({
      ...each,
      questions: each.questions.filter(
        (eachQuestion) =>
          (eachQuestion.question || "")
            ?.toLowerCase()
            .indexOf(searchKey.toLowerCase()) > -1 ||
          (eachQuestion.answer || "")
            ?.toLowerCase()
            .indexOf(searchKey.toLowerCase()) > -1
      ),
    }));
  const noAnswerFound =
    filteredCategorizedQuestions.length === 0 && searchKey !== "";

  const handleSelectCategory = (category: FaqCategory) => {
    const isSelected = selectedCategories.find(
      (each) => each.id === category.id
    );
    if (isSelected) {
      const newSelectedCategories = selectedCategories.filter(
        (each) => each.id !== category.id
      );
      setSelectedCategories(newSelectedCategories);
    } else {
      const newSelectedCategory = selectedCategories.concat(category);
      setSelectedCategories(newSelectedCategory);
    }
  };

  const filteredCategories =
    searchKey !== ""
      ? filteredCategorizedQuestions
      : selectedCategories.length > 0
      ? categorizedFaqs.filter((each) =>
          selectedCategories.find(
            (eachCategory) => eachCategory.id === each.category.id
          )
        )
      : categorizedFaqs;

  const isLoading = isLoadingFaqCategories || isLoadingFaqs;

  return (
    <div>
      <Typography variant="h6" className={styles.title}>
        Find solutions fast
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Discover all the answers you need to your questions here.
      </Typography>
      <TextField
        value={searchKey}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setSearchKey(event.target.value)
        }
        placeholder="Search by keyword..."
        fullWidth
        className={styles.textField}
      />

      {noAnswerFound && (
        <div className={styles.emptyContainer}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src="/images/empty-search-faq-result.svg" alt="icon" />
          <Typography variant="h6">No results found</Typography>
          <Typography variant="body2" color="text.secondary">
            Oops! It seems we don&apos;t have an answer for that just yet.
          </Typography>
          <Button
            variant="text"
            onClick={() => setIsSubmitQuestionDialogOpen(true)}
          >
            Can&apos;t find your answer? Ask us!
          </Button>
        </div>
      )}

      {searchKey === "" && (
        <>
          {filteredCategories.length > 0 && (
            <Typography variant="h6" className={styles.title}>
              Explore topics
            </Typography>
          )}
          <div className={styles.categoryContainer}>
            {isLoading
              ? [1, 2, 3].map((each, idx) => (
                  <Skeleton
                    key={idx}
                    variant="rectangular"
                    className={styles.categoryLoader}
                  />
                ))
              : categorizedFaqs.map((each) => (
                  <Tag
                    key={each.category.id}
                    label={each.category.name}
                    variant="outlined"
                    color={
                      selectedCategories.find(
                        (eachCategory) => each.category.id === eachCategory.id
                      )
                        ? "primary"
                        : "info"
                    }
                    className={styles.tag}
                    onClick={() => handleSelectCategory(each.category)}
                  />
                ))}
          </div>
        </>
      )}
      {isLoading && (
        <>
          <Skeleton variant="text" className={styles.titleLoader} />
          {[1, 2, 3].map((each) => (
            <Skeleton
              key={each}
              variant="rectangular"
              className={styles.faqLoader}
            />
          ))}
        </>
      )}
      {filteredCategories.map((each) => (
        <>
          <Typography
            variant="h6"
            color="text.secondary"
            className={styles.categoryTitle}
          >
            {each.category.name}
          </Typography>
          {each.questions.map((eachQuestion) => (
            <FAQ
              key={eachQuestion.id}
              question={eachQuestion.question || ""}
              answer={eachQuestion.answer || ""}
              faqId={eachQuestion.id}
            />
          ))}
        </>
      ))}

      {isSubmitQuestionDialogOpen && (
        <SubmitQuestionDialog
          onClose={() => {
            setIsSubmitQuestionDialogOpen(false);
            setSearchKey("");
          }}
        />
      )}
    </div>
  );
};

export default HelpCentreTab;
