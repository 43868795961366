import { useFlagsmith } from "flagsmith/react";

const useFeatureFlags = () => {
  const flagsmith = useFlagsmith();
  const gworldAcademy = flagsmith.getValue<boolean>("gworldAcademy");
  const gworldMarketplace = flagsmith.getValue<boolean>("gworldMarketplace");
  const paymentLock = flagsmith.getValue<boolean>("paymentLock");
  const eligibleForPaymentPlanBanner = flagsmith.getValue<boolean>(
    "eligibleForPaymentPlanBanner"
  );
  const eligibleForFullPaymentBanner = flagsmith.getValue<boolean>(
    "eligibleForFullPaymentBanner"
  );
  const jobsCoordinatorUnlocked = flagsmith.getValue<boolean>(
    "jobsCoordinatorUnlocked"
  );
  const jobsPaymentLocked = flagsmith.getValue<boolean>("jobsPaymentLocked");
  const jobsTimeLocked = flagsmith.getValue<boolean>("jobsTimeLocked");
  const resourceArrivalLocked = flagsmith.getValue<boolean>(
    "resourceArrivalLocked"
  );
  const resourceTimeLocked = flagsmith.getValue<boolean>("resourceTimeLocked");
  const resourcePaymentLocked = flagsmith.getValue<boolean>(
    "resourcePaymentLocked"
  );
  const jobsIntroductionCallLocked = flagsmith.getValue<boolean>(
    "jobsIntroductionCallLocked"
  );
  const showInstallAppBanner = flagsmith.getValue<boolean>(
    "showInstallAppBanner"
  );
  const lateCancellationFlow = flagsmith.getValue<boolean>(
    "lateCancellationFlow"
  );

  const visaPaymentLocked = flagsmith.getValue<boolean>("visaPaymentLocked");

  const isLoading = Boolean(flagsmith.getAllTraits() === null);

  return {
    flags: {
      gworldAcademy,
      gworldMarketplace,
      paymentLock,
      eligibleForPaymentPlanBanner,
      eligibleForFullPaymentBanner,
      jobsCoordinatorUnlocked,
      jobsPaymentLocked,
      jobsTimeLocked,
      resourceArrivalLocked,
      resourceTimeLocked,
      resourcePaymentLocked,
      jobsIntroductionCallLocked,
      visaPaymentLocked,
      showInstallAppBanner,
      lateCancellationFlow,
    },
    flagsmith,
    isLoading,
  };
};

export default useFeatureFlags;
