import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "../fetchWithAuth";

export enum Division {
  Volunteer = "Volunteer",
  AuPair = "Au Pair",
  WorkingHoliday = "Working Holiday",
  Study = "Study",
  Internship = "Internship",
  SummerCamp = "Summer Camp",
  Tutor = "Tutor",
  Tour = "Tour",
  Teach = "Teach",
  Remote = "Remote",
  Superlite = "SuperLite",
  FIT = "FIT",
}

export enum TripStatus {
  Cancelled = "cancelled",
  OnHold = "On Hold",
  OnHoldFromSales = "On Hold from Sales",
  Cancelling = "Cancelling",
  TripCancelled = "Trip Cancelled",
  UnderAgeWaitingApproval = "Underage - Waiting Approval",
  Transfer = "Transfer",
  Complete = "Complete",
}

export type Trip = Partial<{
  cancellation?: Partial<{
    id: number;
    fname: string;
    lname: string;
    email: string;
    phone: string;
    dob: string;
    booking_country: string;
    payment_stage: unknown;
    trip: string;
    tc_accepted: number;
    cancel_customer_reason: string;
    cancel_status: string;
    cancel_reason: string;
    cancel_submit_date: string;
    internal_comments: string;
    tid: number;
  }>;
  cid: number;
  count: number;
  country_code: string;
  deposit_complete: number;
  destination: string;
  division: Division;
  duration: string;
  id: number;
  region: string;
  sales_register_id: number;
  sales_register_status: string;
  sales_register_processed: number; // 0 or 1
  status: TripStatus;
  trip_code: string;
  trip_group_id: number;
  trip_id: number;
  trip_name: string;
  under_age: number;
  user_ip: string;
  user_selected: number;
  _ca: number;
  _cb: number;
  _lma: number;
  _lmb: number;
}>;

type Trips = Array<Trip>;
type QueryError = {};

const useTrips = (
  options?: UseQueryOptions<Trips, QueryError, Trips, QueryKey>
) => {
  const url = `${GATEWAY_URL}/api/trips`;
  const cacheKey = [url];
  const { fetchWithAuth } = useFetchWithAuth();

  return useQuery<Trips, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<Trips>(url);
    },
    {
      ...options,
    }
  );
};
export default useTrips;
