import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import styles from "./Header.module.scss";
import { RouteFeature, useLock, useRoutes } from "@/hooks";
import { TabNavigation } from "@/components/Navigation";
import TripSelectorMenu from "./TripSelectorMenu";
import NotificationMenu from "./NotificationMenu";
import ExternalLinksMenu from "./ExternalLinksMenu";
import ProfileMenu from "./ProfileMenu";
import RewardMenu from "./RewardMenu";
import { useResponsive } from "@/hooks";
import Logo from "./Logo";
import ChatIconButton from "./ChatIconButton";
import RewardIconButton from "./RewardIconButton";

const Header: React.FC<{ className?: string }> = ({ children, className }) => {
  const { routes, getMatchedRoute } = useRoutes();
  const { isAllowedToNavigate } = useLock();
  const { isMobile } = useResponsive();
  const { route } = getMatchedRoute();
  const hasMobileTripSelector = route?.features?.includes(
    RouteFeature.HasMobileTripSelector
  );

  return (
    <MuiAppBar
      color="inherit"
      elevation={isMobile ? 0 : 3}
      className={className}
    >
      <MuiToolbar className={styles.toolbar}>
        {Boolean(children) ? (
          children
        ) : (
          <>
            <Logo />
            {(hasMobileTripSelector || !isMobile) && <TripSelectorMenu />}
            {isAllowedToNavigate ? (
              <>
                <TabNavigation routes={routes} />
                <RewardMenu />

                <NotificationMenu />
              </>
            ) : (
              <div className={styles.emptyNavigatorContainer} />
            )}
            <ExternalLinksMenu />
            <RewardIconButton className={styles.rewardMobileButton} />
            <ChatIconButton stickToEnd />
            <ProfileMenu />
          </>
        )}
      </MuiToolbar>
    </MuiAppBar>
  );
};

export default Header;
