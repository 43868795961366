import { useEffect, useState } from "react";
import { Division } from "@/fetch/gworld";

import useCurrentTrip from "./useCurrentTrip";

const useDivisionBanner = () => {
  const imgDefault = `https://dvhvji4glt0rj.cloudfront.net/img/destinations/default/banner.png`;
  const [currentBannerImage, setCurrentBannerImage] =
    useState<string>(imgDefault);

  const { currentTrip } = useCurrentTrip();

  const DivisionImages = {
    [Division.AuPair]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/au-pair/banner.jpg",
    [Division.Internship]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/internship/banner.jpg",
    [Division.Remote]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/remote/banner.jpg",
    [Division.Study]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/study/banner.jpg",
    [Division.SummerCamp]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/summer-camp/banner.jpg",
    [Division.Teach]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/teach/banner.jpg",
    [Division.Tour]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/tour/banner.jpg",
    [Division.Tutor]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/tutor/banner.jpg",
    [Division.Volunteer]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/volunteer/banner.jpg",
    [Division.WorkingHoliday]:
      "https://res.cloudinary.com/gwatco/image/upload/dpr_auto/t_banner-lg/f_auto/v1718397000/website/division/working-holiday/banner.jpg",
    [Division.Superlite]:
      "https://gwatco-res.cloudinary.com/image/upload/f_auto,q_auto,ar_100:30,w_650,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/superlite.jpg",
    [Division.FIT]: "",
  };

  useEffect(() => {
    const currentDivision = currentTrip?.division;
    if (currentDivision) {
      const currentBanner = DivisionImages[currentDivision];
      setCurrentBannerImage(currentBanner || imgDefault);
    }
  }, [currentTrip]);

  return { currentBannerImage };
};

export default useDivisionBanner;
