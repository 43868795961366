import { useEffect, useState } from "react";
import { useCurrentTrip, useLock, useResponsive } from "@/hooks";
import { Division } from "@/fetch/gworld";

const useIntercomHandler = (input?: number | string) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { currentTrip } = useCurrentTrip();
  const { isProfileSetup, isAllowedToNavigate, isLoading } = useLock();
  const { isMobile } = useResponsive();
  const [bootIntercom, setBootIntercome] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setBootIntercome(true), 30000);
  }, []);

  useEffect(() => {
    if (!bootIntercom) return;
    if (currentTrip?.division !== Division.Superlite) return;
    console.log("boot");
    //@ts-ignore
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "v4kbpaee",
    });
  }, [bootIntercom, currentTrip]);

  const intercomEl = document.getElementsByClassName(
    "intercom-lightweight-app"
  )?.[0];

  const intercomActiveEl =
    document.getElementsByClassName("intercom-namespace")?.[0];
  const intercomScreenEl = document.getElementsByClassName(
    "intercom-lightweight-app-launcher"
  )?.[0];
  const intercomScreenActiveEl = document.getElementsByClassName(
    "intercom-with-namespace-52k34s"
  )?.[0];

  intercomEl?.setAttribute("style", "display:none");
  intercomActiveEl?.setAttribute("style", "display:none");

  if (
    currentTrip?.division === Division.Superlite &&
    isAllowedToNavigate &&
    isProfileSetup &&
    !isLoading
  ) {
    console.log("here");
    intercomEl?.setAttribute("style", "display:revert");
    intercomActiveEl?.setAttribute("style", "display:revert");
    if (isMobile) {
      intercomScreenEl?.setAttribute("style", "bottom:64px");
      intercomScreenActiveEl?.setAttribute("style", "bottom:64px");
    }
  }

  const checkForIntercom = () => {
    const intercomEl = document.getElementsByClassName(
      "intercom-lightweight-app"
    )?.[0];

    const intercomActiveEl =
      document.getElementsByClassName("intercom-namespace")?.[0];
    const intercomScreenEl = document.getElementsByClassName(
      "intercom-lightweight-app-launcher"
    )?.[0];
    const intercomScreenActiveEl = document.getElementsByClassName(
      "intercom-with-namespace-52k34s"
    )?.[0];

    intercomEl?.setAttribute("style", "display:none");
    intercomActiveEl?.setAttribute("style", "display:none");

    console.log(currentTrip?.division === Division.Superlite);
    console.log(isAllowedToNavigate);
    console.log(isProfileSetup);
    console.log(isLoading);
    console.log(isLoaded);

    if (
      currentTrip?.division === Division.Superlite &&
      isAllowedToNavigate &&
      isProfileSetup &&
      !isLoading &&
      isLoaded
    ) {
      console.log("here");
      intercomEl?.setAttribute("style", "display:revert");
      intercomActiveEl?.setAttribute("style", "display:revert");
      if (isMobile) {
        intercomScreenEl?.setAttribute("style", "bottom:64px");
        intercomScreenActiveEl?.setAttribute("style", "bottom:64px");
      }
    }
  };

  const checkFirst = () => {
    const intercomEl = document.getElementsByClassName(
      "intercom-lightweight-app"
    )?.[0];
    intercomEl?.setAttribute("style", "display:none");
  };

  //@ts-ignore
  window.Intercom("onHide", function () {
    setTimeout(() => setOpen(!open), 50);
  });

  useEffect(() => {
    // checkFirst();
  }, []);

  useEffect(() => {
    // checkForIntercom();
  }, [
    currentTrip,
    isAllowedToNavigate,
    isProfileSetup,
    open,
    isMobile,
    isLoading,
    isLoaded,
  ]);

  const enableIntercom = () => {
    console.log("enable");
    setIsLoaded(true);
  };

  return { checkForIntercom, enableIntercom };
};

export default useIntercomHandler;
